import { Component, Input, inject } from '@angular/core';
import { SearchModalService } from '../search-modal/search-modal.service';
import { TGoogleCaptchaAssessment } from '../search-modal/search-modal.interface';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
  standalone: true,
})
export class SearchInputComponent {
  private readonly _searchModalService: SearchModalService = inject(SearchModalService);

  @Input() placeholder?: string;

  public async openSearchModal() {
    const captchaAssessment: TGoogleCaptchaAssessment = await this._searchModalService.reCaptcha();

    if (captchaAssessment.riskAnalysis.score > 0.3) {
      this._searchModalService.toggleSearchModal(true);
      return;
    }

    this._searchModalService.toggleCaptchaModal(true);
  }
}
